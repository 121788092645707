import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordingBox from '../pages/AccordingBox'
import teenused1 from '../data/teenused1.json';
import teenused2 from '../data/teenused2.json';
//import hanza from '../assets/hanza.png';
//import getpro from '../assets/get-a-pro.svg';
//import fortaco from '../assets/fortaco.svg';
//import energia from '../assets/eesti-energia.png';

export default function Faq() {
   // let textResult1 = [];
  //  let textResult2 = [];

    return (
        <>
        <div className="toode-page">    
            <div className="relative-container">
                <h1>Teenused, tööd</h1>
            </div>
        </div>
        <div className="faq-page">
            <div className="gray-bg"></div>
            <div className="faq-container d-flex">
                <div className="column w-50">
                <Accordion defaultActiveKey="1">
                            {teenused1.map((data) => (
                                <AccordingBox data={data}  className="m-2"/>
                            ))}
                        </Accordion>

                    
                </div>

                <div className="column w-50">
                <Accordion defaultActiveKey="1">
                            {teenused2.map((data) => (
                                <AccordingBox data={data}  className="m-2"/>
                            ))}
                        </Accordion>
                   
                </div>
            </div>
        </div>
        </>
    )
}