import React from 'react';


export default function Footer() {

   
    return (
        <footer>
             <div className="top-row">
                <div className="column">
                    <h2>PEAME OMA LUBADUSTEST KINNI</h2>
                    <h1>KAEVE- JA / VÕI PINNASETÖÖD</h1>
                

                    <div className="buttons">
                    <div className="btn-container">
                            <a href='#contacts'>KÜSI MEILT HINNAPAKKUMINE</a>
                        </div>                       
                    </div>
                </div>
            </div>
            <nav className='pt-3'>                
                <p>MEILETOP OÜ | Copyright 2023 ©</p>
            </nav>
        </footer>
    )
}