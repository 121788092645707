import React from 'react';
import excavator from '../assets/excavator.png';
import dumper from '../assets/dumper.png';
import tractor from '../assets/excavator-2.png';


export default function Program({ fag }) {
    function faqClick() {
        fag.current.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <div className="program-page">
            <div className="gray-bg"></div>
            
            <div className="relative-container">
                <h1>Teenused</h1>
                <div className="statistics-page1" >
                <div className="statistics-container">
                <div className="column">
                    
                    <div className="program-container">
                        <div className="program">
                            <h3>ROOMIKEKSKAVAATORI RENT</h3>
                            
                            <div className="program-duration">
                                <img src={excavator} alt="excavator" width={90} />
                                <p style={{height:'195px'}}>Pika noolega JCB roomikekskavaatori rent. Juurde on võimalik tellida kaevekopad ja planeerkopad. Rendime masinaid üle terve Eesti</p>
                            </div>
                            
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a onClick={faqClick} className="btn-container">
                                <button>Rohkem</button>
                            </a>
                        </div>
                       
                    </div>
                </div>

                <div className="column">                    
                
                    <div className="program-container">                        
                        <div className="program">
                            <h3>VEOTEENUSED</h3>
                           
                            <div className="program-duration">
                                <img src={dumper} alt="dumper"  width={90}  />
                                <p style={{height:'195px'}}>Erineva puistematerjali veoteenus. Olgu selleks kas mänguväljaku jagu liiva erakliendile või veoteenus suurobjektile.</p>
                            </div>
                            
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a onClick={faqClick} className="btn-container">
                                <button>Rohkem</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="column">                    
                
                    <div className="program-container">                        
                        <div className="program">
                            <h3>KAEVETÖÖD- JA PINNASETÖÖD</h3>
                           
                            <div className="program-duration">
                                <img src={tractor} alt="tractor"  width={90}  />
                                <p style={{height:'195px'}}>Kaevame ja puhastame tiike ja tehisjärvi. Samuti teeme väljakaeveid ja pinnasetöid ehitusobjektidel. Vajadusel aga liigutame kasvõi mägesid ühest kohast teise.</p>
                            </div>
                            
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a onClick={faqClick} className="btn-container">
                                <button>Rohkem</button>
                            </a>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
        </div>
    )
}