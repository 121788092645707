/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import gallery from '../data/gallery.json';
import Carousel from 'react-bootstrap/Carousel';


export default function Gallery() {
    //const pictures = [picture1, picture2, picture3, picture4, picture5, picture6, picture7, picture8];
    return (
        <div className="gallery">
            <h2>GALERII</h2>
            <Carousel id='carousel'>
                {gallery.map((picture, index) => {
                    return (
                        <Carousel.Item key={index} >
                            <img style={{height:'590px'}}
                                className='d-block w-100'
                                src={picture.image}
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </div>
    )
}