/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

export default function AboutUs({ program }) {
    function programClick() {
        program.current.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <div className="about-us">
            <div className='about-us_img'>
                <img />
            </div>
            <div className='shape_about-us'>
                <div>
                    <h2>Meist</h2>
                    <p>MEILETOP OÜ</p>
                    <p>2003 aastast on põhitegevusaladeks kaeve-, pinnase- ja maaparandustööd. <br></br>Pakume ka masinate renti koos operaatoriga. <br></br>Omame tänapäevast tehnikat miniekskavaatorist kuni 24 tonnise pika noolega ekskavaatorini välja.<br></br>
Meiletop OÜ-le on väljastatud maaparandus alal tegutsevate ettevõtjate registreering MEO 154-00</p>
                    <div className="btn-container">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={programClick}>TEENUSED</a>
                    </div>
                </div>
            </div>
        </div>
    )
}