/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import masina from '../data/masinapark.json';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';


export default function Teachers() {
    return (
        <div className="teachers">          
            <Container fluid className="mt-5">
      <Row>
        <Col md={{ span: 9, offset: 2 }}>
        <h2>MASINAPARK</h2>
          <Row xs={1} md={3} className="g-4">
            {masina.map((data) => (
              <Col key={data.id} >
                <Card className="m-2" key={data.id} style={{height:'480px'}}>
                  <Card.Img variant="top" src={data.image} style={{ height: 204 }} />
                  <Card.Body style={{fontFamily: 'League Spartan'}}>
                    <Card.Title>{data.name}</Card.Title>
                    <Card.Text>{data.description}</Card.Text>
                    <Card.Text className='mt-3'><h6>{data.hind}</h6></Card.Text>
                    <Card.Text className='mt-3'>{data.role}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
        </div>
    )
}