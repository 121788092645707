import React from 'react';
import location from '../assets/location-icon.svg';
import phoneIcon from '../assets/phone-icon.svg';
import mail from '../assets/mail-icon.svg';
import info from '../assets/info-icon.svg';
import website from '../assets/website-icon.svg';


export default function Contacts() {
 
    

    return (
        <div className="contacts-page">
            <div className="top-container">
                <h1>KONTAKT</h1>
                <p>TEKKIS KÜSIMUS? VÕTA JULGELT ÜHENDUST!  +372 56 451 927</p>         
            </div>
            <div className="left-column w-50">
            <div className="contact-row">
                    <div className="img-container">
                        <img src={location} alt="location" />
                    </div>
                    <div className="info-container">
                        <h4>Location</h4>
                        <p>Sava küla, Luunja vald 62214, Tartumaa</p>
                    </div>
                </div>
                <div className="contact-row">
                    <div className="img-container">
                        <img src={phoneIcon} alt="phone" />
                    </div>
                    <div className="info-container">
                        <h4>Phone</h4>
                        <p>Meelis Lemsalu / Tegevjuht +372 56 451 927</p>
                    </div>
                </div>
                <div className="contact-row">
                    <div className="img-container">
                        <img src={mail} alt="mail" />
                    </div>
                    <div className="info-container">
                        <h4>E-mail</h4>
                        <p><a href='mailto:info@meiletop.ee'>info@meiletop.ee</a></p>
                    </div>
                </div>
                <div className="contact-row">
                    <div className="img-container">
                        <img src={info} alt="meiletop" />
                    </div>
                    <div className="info-container">
                        <h4>Reg. nr. 10849713</h4>
                       
                    </div>
                </div> 
                <div className="contact-row">
                    <div className="img-container">
                        <img src={website} alt="meiletop" />
                    </div>
                    <div className="info-container">
                        <h4>Website</h4>
                        <p><a href='http://www.meiletop.ee/'>http://www.meiletop.ee/</a></p>
                    </div>
                </div>             
            </div >

            <div className="right-column w-50">
            <iframe className='frame' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2091.6037373004297!2d26.96489267697888!3d58.383219474169984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eb2df71ca73d3b%3A0x4be7d3c310dfd2c2!2sMeiletop%20O%C3%9C!5e0!3m2!1sru!2see!4v1689177431980!5m2!1sru!2see" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div >
    )
}