import React from 'react';
import excavator from '../assets/excavator.png';
import dumper from '../assets/dumper.png';
import tractor from '../assets/excavator-2.png';

export default function Statistics() {
    return (
        <div className="statistics-page">
            <div className="bg-image"></div>
            <div className="statistics-container">
                <div className="column">
                    <div className="img-container">
                        <img src={excavator} alt="excavator" />
                    </div>
                    <div className="text-container">
                        <h2>ROOMIKEKSKAVAATORI RENT</h2>
                        
                    </div>
                </div>
                <div className="column">
                    <div className="img-container">
                        <img src={dumper} alt="dumper" />
                    </div>
                    <div className="text-container">
                        <h2>VEOTEENUSED</h2>
                        
                    </div>
                </div>
                <div className="column">
                    <div className="img-container">
                        <img src={tractor} alt="tractor" />
                    </div>
                    <div className="text-container">
                        <h2>KAEVETÖÖD- JA PINNASETÖÖD</h2>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}