import React from 'react';
import { Accordion } from 'react-bootstrap';

export default function AccordingBox({ data }) {
  let textResult = [];
  if (typeof data.text[0] === 'string') {
    textResult = (
      <>
        <p className="ft-5">{data.text[0]}</p>
      </>
    );
  } else {
    for (let i of data.text) {
      textResult.push(getList(i));
    }
  }
  return (
    <>
      <Accordion.Item eventKey={data.key} className="m-2">
        <Accordion.Header>{data.header}</Accordion.Header>
        <Accordion.Body>
          {data.description}
          {textResult}
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}
function getList(array) {
  let arrayText = array.slice(0, -1);
  let text = (
    <>
      <ul style={{ paddingLeft: '25px' }}>
        {arrayText.map((data) => (
          <li style={{ listStyle: 'disc' }}>{data}</li>
        ))}
      </ul>
    </>
  );
  return text;
}
