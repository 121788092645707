/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export default function Home({ gallery, contacts }) {
    function programClick() {
        gallery.current.scrollIntoView({ behavior: 'smooth' });
    }
    function contactsClick() {
        contacts.current.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <div>
            <div className="main-page"  style={{backgroundImage:`url("/images/5-1.jpg")` }}>
                <div className="left-side">
                    <div className="text">
                        <h1>TEOSTAME TÖID JA RENDIME</h1>
                        <h1>MASINAID ÜLE EESTI</h1>
                    </div>
                    <p>Masinad on võimalik varustada erinevate tööorganitega.</p>
                    <div className="buttons">
                        <div className="btn-container">
                            <a onClick={programClick}>Galerii</a>
                        </div>
                        <div className="btn-container">
                            <a onClick={contactsClick}>Kontakt</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}